<template>
	<form @submit.prevent="submitHandler">
		<recess-modal :show-modal="isModalVisible" @close="close">
			<template slot="modal-title">
				<h3 class="qa-edit-orders-in-group-pop-up-title">{{ PROVIDER.Order.OrderOverview.Popup.EditOrdersInGroup.Title }}</h3>
				<p v-if="showApprovalSection" class="qa-edit-orders-in-group-pop-up-information">{{ PROVIDER.Order.OrderOverview.Popup.EditOrdersInGroup.Description }}</p>
			</template>

			<template slot="modal-body">
				<recess-multi-select
					:selected-options="selectedOption"
					:options="selectOptions"
					:search-input="true"
					:single-select="true"
					:select-options-text="selectOptions && selectOptions[0].displayText"
					class="mb-4"
					@input="(newValue) => onOptionChange(newValue)"
				/>

				<div v-show="showApprovalSection">
					<div class="d-flex justify-content-between align-items-center flex-wrap mb-2">
						<label class="w-30">{{ PROVIDER.Order.OrderOverview.Popup.EditOrdersInGroup.AcceptOrder.LegalCompany.Label }}</label>
						<recess-multi-select
							:selected-options="selectedLegalCompanyId"
							:options="legalCompanyOptions"
							:search-input="true"
							:single-select="true"
							class="w-70 qa-legal-company-select"
							:placeholder="PROVIDER.Order.OrderOverview.Popup.EditOrdersInGroup.AcceptOrder.LegalCompany.Placeholder"
							@input="(newValue) => legalCompanySelectHandler(newValue)"
						/>
						<p v-if="!isAdministrator" class="mt-3 font-italic">
							{{ PROVIDER.Order.OrderOverview.Popup.EditOrdersInGroup.AcceptOrder.LegalCompanyButton.PartOne }}
							<recess-button
								:title="PROVIDER.Order.OrderOverview.Popup.EditOrdersInGroup.AcceptOrder.LegalCompanyButton.Title"
								variant="variant4"
								class="mb-4"
								url="/leveranciersgegevens/handelsnamen"
							/>
							{{ PROVIDER.Order.OrderOverview.Popup.EditOrdersInGroup.AcceptOrder.LegalCompanyButton.PartTwo }}
						</p>
					</div>

					<!-- if user selects a LC, we get LC by id and prefill the values in the input fields -->
					<template v-if="selectedLegalCompanyId && legalCompanyDetails">
						<legal-company-detail :legal-company-details="legalCompanyDetails" />
					</template>
				</div>

				<div v-show="showRejectSection">
					<div class="d-flex justify-content-start align-items-center flex-wrap mb-2">
						<label :class="['w-30', cancellationReasonError ? 'align-self-start' : '']">{{
							PROVIDER.Order.OrderOverview.Popup.EditOrdersInGroup.RejectOrder.CancellationReason.Label
						}}</label>
						<recess-multi-select
							:selected-options="orderStatusTransitionItem.cancellationReason"
							:options="getCancellationReasons"
							:single-select="true"
							:search-input="true"
							:error-message="cancellationReasonError"
							:placeholder="PROVIDER.Order.OrderOverview.Popup.EditOrdersInGroup.RejectOrder.CancellationReason.Placeholder"
							class="w-70 qa-rejection-reason-select"
							@input="(newValue) => setCancellationReason(newValue)"
						/>
					</div>
					<recess-textarea-input
						v-model="orderStatusTransitionItem.message"
						:label-text="PROVIDER.Order.OrderOverview.Popup.EditOrdersInGroup.RejectOrder.CancellationMessage.Label"
						:error-message="cancellationMessageError"
						class="qa-rejection-optional-input mt-4"
						rows="15"
						maxlength="2000"
					/>
				</div>
			</template>

			<template slot="modal-footer">
				<div class="d-flex justify-content-end">
					<recess-button variant="tertiary" :title="BUTTON_TEXT.cancel" class="qa-edit-orders-cancel-button" @click.native.prevent="close()" />
					<recess-button variant="secondary" type="submit" :disabled="!enableSaveButton" :title="BUTTON_TEXT.submit" class="ml-3 qa-edit-orders-accept-button" />
				</div>
			</template>
		</recess-modal>
	</form>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import { patchItem, postItem, getItems, getItemById, getEnumList } from '@/../../shared/api/SharedClient'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { API_CALL_URL_PATHS, BUTTON_TEXT, WAIT_TIME } from '@/../../shared/constants/constantsGeneral.json'
import UserService from '../../../../shared/services/UserService'
import { defaultErrorMessage, isRequiredErrorMessage, legalCompanyMissingCreditNumberError,legalCompanyMissingVatNumberError, orderSuccessAction } from '../../../../shared/constants/validationMessageHelper.js'

const LegalCompanyDetail = () => import('@/components/molecules/shared/LegalCompanyDetail')

export default {
	name: 'EditOrdersInGroupPopUpComponent',
	mixins: [validationMixin],
	components: {
		LegalCompanyDetail
	},
	props: {
		isModalVisible: {
			type: Boolean,
			default: false
		},
		orders: {
			type: Array,
			default: () => []
		},
		providerId: {
			type: String,
			default: null
		}
	},
	validations: {
		orderStatusTransitionItem: {
			cancellationReason: { required },
			message: { required }
		}
	},
	data() {
		return {
			PROVIDER,
			userService: new UserService(),
			isAdministrator: false,
			BUTTON_TEXT,
			//Accept orders
			selectedLegalCompanyId: null,
			legalCompanyList: [],
			legalCompanyDetails: null,
			//Reject orders
            courseCancellationReasons: null,
			orderStatusTransitionItem: this.getEmptyOrderStatusTransition(),
			//
			selectedOption: null,
			selectOptions: [
				{
					displayText: PROVIDER.Order.OrderOverview.Popup.EditOrdersInGroup.Options.Accept,
					value: 1
				},
				{
					displayText: PROVIDER.Order.OrderOverview.Popup.EditOrdersInGroup.Options.Reject,
					value: 2
				}
			]
		}
	},
	computed: {
		getCancellationReasons() {
            return this.courseCancellationReasons
        },
		showApprovalSection() {
			return this.selectedOption === 1
		},
		showRejectSection() {
			return this.selectedOption === 2
		},
		legalCompanyOptions() {
			if (this.legalCompanyList) {
				return this.legalCompanyList.map((item) => {
					return {
						value: item.id,
						displayText: item.name
					}
				})
			}
			return null
		},
		isLegalCompanyValid() {
			const legalCompany = this.legalCompanyList !== null && this.legalCompanyList.find((x) => x.id === this.selectedLegalCompanyId)

			if (!legalCompany) return

			return legalCompany.creditNumber !== null && legalCompany.creditNumber !== undefined
		},
		isLegalCompanyVatNumberValid() {
			const legalCompany = this.legalCompanyList !== null && this.legalCompanyList.find((x) => x.id === this.selectedLegalCompanyId)

			if (!legalCompany) return

			return legalCompany.vatNumber !== null && legalCompany.vatNumber !== undefined && legalCompany.vatNumber !== ''
		},
		legalCompanyFilter() {
			return this.providerId ? `providerId eq ${this.providerId} and deleted eq false` : null
		},
		cancellationReasonError() {
			return isRequiredErrorMessage('cancellationReason', 'Reden voor annulering', this.$v.orderStatusTransitionItem.cancellationReason)
		},
		cancellationMessageError() {
			return isRequiredErrorMessage('message', 'Bericht voor annulering', this.$v.orderStatusTransitionItem.message)
		},
		enableSaveButton() {
			return (this.selectedLegalCompanyId && this.showApprovalSection) || (!this.$v.$invalid && this.showRejectSection)
		}
	},
	watch: {
		isModalVisible: function watchPropsChange(newValue) {
			// reset data status when modal is closed by watching the value
			// change of isModalvisible from its parent 'OrderList'
			if (newValue === false && this.legalCompanyList.length > 1) {
				this.legalCompanyDetails = null
				this.selectedLegalCompanyId = null
			}
		},
		providerId: {
			handler() {
				if (this.providerId) {
					this.getLegalCompanyList()
				}
			}
		},
		legalCompanyList: {
			handler() {
				if (this.legalCompanyList.length === 1) {
					this.legalCompanySelectHandler(this.legalCompanyList[0].id)
				}
			}
		}
	},
	async created() {
		this.isAdministrator = await this.userService.isAdministrator()
	},
	async mounted() {
		await this.getLegalCompanyList()
		this.getCoursesRejectOrderReasons()
	},
	methods: {
		onOptionChange(newValue) {
			this.selectedOption = newValue
		},
		close() {
			this.selectedOption = 1
			this.orderStatusTransitionItem = this.getEmptyOrderStatusTransition()

			this.$emit('close')
		},
		validateLegalCompanyCreditNumber() {
			let toastNotification = {
				type: null,
				message: null
			}
			if (!this.isLegalCompanyValid && this.showApprovalSection) {
				toastNotification = {
					type: 'error',
					message: legalCompanyMissingCreditNumberError()
				}

				this.$store.dispatch('toastNotificationModule/add', toastNotification, {
					root: true
				})
				return true
			}
			return false
		},
		validateLegalCompanyVatNumber() {
			let toastNotification = {
				type: null,
				message: null
			}
			if (!this.isLegalCompanyVatNumberValid && this.showApprovalSection) {
				toastNotification = {
					type: 'error',
					message: legalCompanyMissingVatNumberError
				}

				this.$store.dispatch('toastNotificationModule/add', toastNotification, {
					root: true
				})
				return true
			}
			return false
		},
		async submitHandler() {
			this.$v.$touch()
			if ((this.showRejectSection && this.$v.$invalid) || this.validateLegalCompanyCreditNumber() || this.validateLegalCompanyVatNumber() ) return

			for (const order of this.orders) {
				if (this.showApprovalSection) {
					// get the details of the order so we have the orderLines
					let orderDetails = await this.getOrderDetails(order.id)

					// patch order to include 'invoice on behalf of' information that allows order status change afterwards
					for (const orderLine of orderDetails.orderLines) {
						await this.patchOrderLinesForEachSequenceNumber(order.id, orderLine.sequenceNumber)
					}

					// approve order status
					this.orderStatusTransitionItem.status = PROVIDER.Order.Shared.OrderStatus.AcceptedProvider
				} else {
					// reject order status
					this.orderStatusTransitionItem.status = PROVIDER.Order.Shared.OrderStatus.RejectedProvider
				}
				await this.postOrderStatusTransition(order.id)

				if (this.showApprovalSection) {
					// check if the order has startmoments
					if (order.firstChoiceStartMomentId) {
						// patch the order for the first startmoment
						let setObjectToBePatched = [
							{
								value: order.firstChoiceStartMomentId,
								path: 'acceptedStartMomentId',
								op: 'replace'
							}
						]

						await this.patchOrder(order.id, setObjectToBePatched)
					}
				}
			}

			this.showNotificationAfterAproveOrReject()

			this.getOrdersAfterRebuildOrderIndex(WAIT_TIME.indexRebuild)
			this.close()
		},
		showManualNotification(type, propertyErrorMessage) {
			const toastNotification = {
				type: type,
				message: propertyErrorMessage
			}

			this.$store.dispatch('toastNotificationModule/add', toastNotification, {
				root: true
			})
		},
		showNotificationAfterAproveOrReject() {
			let toastNotification

			if (this.showApprovalSection) {
				toastNotification = {
					type: 'success',
					message: orderSuccessAction('geaccepteerd', true)
				}
			} else {
				toastNotification = {
					type: 'success',
					message: orderSuccessAction('afgewezen', true)
				}
			}

			this.$store.dispatch('toastNotificationModule/add', toastNotification, {
				root: true
			})
		},
		getOrdersAfterRebuildOrderIndex(millisecond) {
			this.timeout = setTimeout(() => {
				this.$emit('refreshOrders')
			}, millisecond)
		},
		async getOrderDetails(orderId) {
			if (!orderId) return
			try {
				return await getItemById(process.env.VUE_APP_ORDER_API_URL, API_CALL_URL_PATHS.orders, orderId, null, false)
			} catch (error) {
				console.error('Something went wrong while retrieving legal companies', error)
			}
		},
		async patchOrder(orderId, objectToBePatched) {
			if (!orderId) return
			try {
				await patchItem(process.env.VUE_APP_ORDER_API_URL, API_CALL_URL_PATHS.orders, orderId, objectToBePatched, false).then((response) => {
					if (!response) return
				})
			} catch (error) {
				console.error('Something went wrong while retrieving legal companies', error)
			}
		},
		async patchOrderLinesForEachSequenceNumber(orderId, orderLineSequenceNumber) {
			const patchObjList = []

			const invoiceOnBehalfOfNameObj = {
				value: this.legalCompanyDetails.name,
				path: 'invoiceOnBehalfOfName',
				op: 'replace'
			}

			patchObjList.push(invoiceOnBehalfOfNameObj)

			const invoiceOnBehalfOfVatNumberObj = {
				value: this.legalCompanyDetails.vatNumber,
				path: 'invoiceOnBehalfOfVatNumber',
				op: 'replace'
			}

			patchObjList.push(invoiceOnBehalfOfVatNumberObj)

			const invoiceOnBehalfOfMerchantIdObj = {
				value: this.legalCompanyDetails.merchantId,
				path: 'invoiceOnBehalfOfMerchantId',
				op: 'replace'
			}

			patchObjList.push(invoiceOnBehalfOfMerchantIdObj)

			await patchItem(process.env.VUE_APP_ORDER_API_URL, API_CALL_URL_PATHS.orders, `${orderId}/lines/${orderLineSequenceNumber}`, patchObjList, false).then((response) => {
				if (!response) return
			})
		},
		async postOrderStatusTransition(orderId) {
			await postItem(process.env.VUE_APP_ORDER_API_URL, `${API_CALL_URL_PATHS.orders}/${orderId}/status-transitions`, this.orderStatusTransitionItem, false)
				.then((response) => {
					if (!response) return
				})
				.catch((error) => {
					this.getNotificationError(error)
					console.error('Something went wrong while posting order status', error)
				})
		},
		async getLegalCompanyList() {
			try {
				const response = await getItems(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.legalCompanies, 1, 99999, 'name asc', this.legalCompanyFilter, false)
				if (!response) return
				this.legalCompanyList = response.items
			} catch (error) {
				console.error('Something went wrong while retrieving legal company list', error)
			}
		},
		legalCompanySelectHandler(newValue) {
			this.selectedLegalCompanyId = newValue
			this.getLegalCompanyDetails()
		},
		async getLegalCompanyDetails() {
			if (!this.selectedLegalCompanyId) return
			try {
				const response = await getItemById(process.env.VUE_APP_PROVIDER_API_URL, API_CALL_URL_PATHS.legalCompanies, this.selectedLegalCompanyId, null, false)
				if (!response) return
				this.legalCompanyDetails = response
			} catch (error) {
				console.error('Something went wrong while retrieving legal companies', error)
			}
		},
		getNotificationError(error) {
			const toastNotification = {
				type: 'error',
				message: defaultErrorMessage
			}
			const errorOutput = error && error.response && error.response.data
			if (errorOutput && errorOutput.error && errorOutput.error.message === legalCompanyMissingCreditNumberError('en')) {
				toastNotification.message = legalCompanyMissingCreditNumberError()
			}

			this.$store.dispatch('toastNotificationModule/add', toastNotification, {
				root: true
			})
		},
		setCancellationReason(newValue) {
			this.orderStatusTransitionItem.cancellationReason = newValue
		},
        async getCoursesRejectOrderReasons() {
            try {
                const response = await getEnumList(
                    process.env.VUE_APP_ORDER_API_URL,
                    API_CALL_URL_PATHS.enumerations,
                    'CourseCancellationReason',
                    null,
                    null,
                    false
                )
                if (!response) return
                
                this.courseCancellationReasons = response

            } catch (error) {
                console.error(
                    'Something went wrong while retrieving cancellation reason enum list',
                    error
                )
            }
        },
		getEmptyOrderStatusTransition() {
			return {
				status: null,
				cancellationReason: null,
				message: null
			}
		}
	}
}
</script>
