<template>
    <recess-modal
        :show-modal="isModalVisible"
        @close="close"
    >
        <template slot="modal-title">
            <h3 class="qa-order-status-modal-title"> {{ PROVIDER.Order.OrderOverview.OrderStatusPopUp.Title }} </h3>
        </template>

        <template slot="modal-body">
            <div v-for="status in sortedStatusTypes" :key="status.Name">
                <span class="d-block font-weight-bold">{{ status.Name }}</span>
                <span>{{ status.Description }}</span>
                <recess-divider show-line />
            </div>
        </template>
    </recess-modal>
</template>

<script>
    import { PROVIDER } from '@/constants/EdumsProviderConstants.json'

    export default {
        name: 'OrderStatusPopupComponent',
        props: {
            isModalVisible: {
                type: Boolean,
                default: false
            }   
        },
        data() {
            return {
                PROVIDER
            }
        },
        computed: {
            sortedStatusTypes() {
            
                return Object.values(this.PROVIDER.Order.OrderOverview.OrderStatusPopUp.StatusTypes).sort((a, b) => a.Name.localeCompare(b.Name));
            }
        },
        methods: {
            close() {
                this.$emit('close')
            }
        }
    }
</script>
