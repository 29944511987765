const filterMixin = {
    data() {
        return {
            // Local filters are used to avoid prop filters triggering instantly after a change
            debounce: null,
            localFilters: {}
        }
    },
    created() {
        this.setSelectedFiltersFromQuery()
    },
    methods: { 
        setSpecificFilter(key, newValue) {
            if (key === 'searchQuery' || key === 'queryByName' || key === 'queryByStartmomentId' || key === 'maxPrice' || key === 'minPrice') {
                clearTimeout(this.debounce)

                this.debounce = setTimeout(() => { 

                    this.localFilters[key] = newValue
                    this.selectedFilters[key] = newValue

                    if(key === 'minPrice') { 
                        this.localFilters['maxPrice'] = this.selectedFilters.maxPrice
                        this.selectedFilters['maxPrice'] = this.selectedFilters.maxPrice 
                    }
                    
                    if(key === 'maxPrice') { 
                        this.localFilters['minPrice'] = this.selectedFilters.minPrice
                        this.selectedFilters['minPrice'] = this.selectedFilters.minPrice 
                    }  

                }, 800)

            } else {
                this.selectedFilters[key] = newValue
                this.localFilters[key] = newValue
            }
        },
        setSelectedFiltersFromQuery() {
            this.localFilters = JSON.parse(JSON.stringify(this.selectedFilters))

            if (Object.keys(this.$route.query).length !== 0) {
                Object.keys(this.selectedFilters).forEach((key) => {
                    if (this.$route.query[key] || this.$route.query[key] === 0) {
                        const decodedValue = decodeURI(this.$route.query[key])

                        if (key === 'maxPrice' || key === 'minPrice') {

                            this.selectedFilters[key] = Number(decodedValue)
                            this.localFilters[key] = Number(decodedValue)
                        } else {
                            this.selectedFilters[key] = decodedValue
                            this.localFilters[key] = decodedValue
                        }
                    }
                })
            }

        }
    }
}

export default filterMixin
