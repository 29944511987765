/*  this mixin contains logics to accept order & reject order that are used in order list 
    and order detail page
*/
import { mapState } from 'vuex'
import { postItem } from "@/../../shared/api/SharedClient"
import { API_CALL_URL_PATHS, WAIT_TIME } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import UserService from '../../../shared/services/UserService'
import { defaultErrorMessage, legalCompanyMissingCreditNumberError, orderSuccessAction } from '../../../shared/constants/validationMessageHelper'

const RejectOrderPopupComponent = () => import('@/components/molecules/RejectOrderPopupComponent')
const AcceptOrderPopupComponent = () => import('@/components/molecules/AcceptOrderPopupComponent')
const AcceptPurchaseAgreementPopupComponent = () => import('@/components/molecules/AcceptPurchaseAgreementPopupComponent')
const AcceptIncompanyPurchaseAgreementPopupComponent = () => import('@/components/molecules/AcceptIncompanyPurchaseAgreementPopupComponent')

const acceptRejectOrderMixin = {
    components: {
        RejectOrderPopupComponent,
        AcceptOrderPopupComponent,
        AcceptPurchaseAgreementPopupComponent,
        AcceptIncompanyPurchaseAgreementPopupComponent
    },
    data() {
        return {
            userService: new UserService(),
            isAdministrator: false,
            timeout: null,
            isAcceptOrderModalVisible: false,
            isAcceptPurchaseAgreementModalVisible: false,
            isAcceptIncompanyPurchaseAgreementModalVisible: false,
            isRejectOrderModalVisible: false,
            orderStatusTransitionItem: null,
            hasErrorPostLegalCompany: false,
            hasErrorPatchOrderLines: false,
            providerId: localStorage.getItem('providerId')
        }
    },
    async mounted() {
        this.$bus.on('update-order-status-transition', this.setOrderStatusTransition)
        this.isAdministrator = await this.userService.isAdministrator()
    },
    computed: {
        ...mapState('providerModule', ['selectedProvider']),
        disableProviderApprovalButtonsForAdmin() { 
            return this.isAdministrator && !this.providerId
        }
    },
    watch: {
        'selectedProvider.id': {
            handler() {
                if (this.isAdministrator) {
                    this.providerId = this.selectedProvider.id 
                }
            }
        }
    }, 
    beforeDestroy() {
        clearTimeout(this.timeout)
    },
    methods: {
        validatePostLegalCompany(newValue) {
            this.hasErrorPostLegalCompany = newValue
        },
        validatePatchOrderLines(newValue) {
            this.hasErrorPatchOrderLines = newValue
        },
        showProviderApprovalButtons(status) {
            return status === PROVIDER.Order.Shared.OrderStatus.PendingProvider
        },
        showAcceptOrderModal() {
            this.isAcceptOrderModalVisible = true
        },
        showAcceptPurchaseAgreementModal() {
            this.isAcceptPurchaseAgreementModalVisible = true
        },
        showAcceptIncompanyPurchaseAgreementModal() {
            this.isAcceptIncompanyPurchaseAgreementModalVisible = true
        },
        closeAcceptOrderModal() {
            this.hasErrorPostLegalCompany = false
            this.hasErrorPatchOrderLines = false
            this.isAcceptOrderModalVisible = false
        },
        closeAcceptPurchaseAgreementModal() {
            this.hasErrorPostLegalCompany = false
            this.hasErrorPatchOrderLines = false
            this.isAcceptPurchaseAgreementModalVisible = false
        },
        closeAcceptIncompanyPurchaseAgreementModal() {
            this.isAcceptIncompanyPurchaseAgreementModalVisible = false
        },
        getOrdersAfterRebuildOrderIndex(millisecond) {
            this.timeout = setTimeout(() => this.getDataOnPageChange(), millisecond)
        },
        acceptOrder(orderId) {
            if (!orderId || this.hasErrorPostLegalCompany || this.hasErrorPatchOrderLines) return
            const newOrderStatus = {
                status: PROVIDER.Order.Shared.OrderStatus.AcceptedProvider
            }
            postItem(
                process.env.VUE_APP_ORDER_API_URL,
                `${API_CALL_URL_PATHS.orders}/${orderId}/status-transitions`,
                newOrderStatus,
                false
            )
                .then(() => {
                    const toastNotification = {
                        type: 'success',
                        message: orderSuccessAction('geaccepteerd')
                    }

                    this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                        root: true
                    })

                    this.getOrdersAfterRebuildOrderIndex(WAIT_TIME.indexRebuild)
                })
                .catch((error) => {
                    this.getNotificationError(error)
                    console.error('Something went wrong while posting order status', error)
                })
        },
        showRejectOrderModal() {
            this.isRejectOrderModalVisible = true
        },
        closeRejectOrderModal() {
            this.isRejectOrderModalVisible = false
        },
        setOrderStatusTransition(newValue) {
            this.orderStatusTransitionItem = newValue
        },
        rejectOrder(orderId) {
            if (!orderId || !this.orderStatusTransitionItem) return
 
            postItem(
                process.env.VUE_APP_ORDER_API_URL,
                `${API_CALL_URL_PATHS.orders}/${orderId}/status-transitions`,
                this.orderStatusTransitionItem,
                false
            )
                .then(() => {
                    const toastNotification = {
                        type: 'success',
                        message: orderSuccessAction('verwerkt.')
                    }

                    this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                        root: true
                    })

                    this.getOrdersAfterRebuildOrderIndex(WAIT_TIME.indexRebuild)
                })
                .catch((error) => {
                    this.getNotificationError()

                    console.error('Something went wrong while posting order status', error)
                })
            this.closeRejectOrderModal()
        },
        getNotificationError(error) {
            const toastNotification = {
                type: 'error',
                message: defaultErrorMessage
            }
            const errorOutput = error && error.response && error.response.data
            if (
                errorOutput &&
                errorOutput.error &&
                errorOutput.error.message ===
                    legalCompanyMissingCreditNumberError('en')
            ) {
                toastNotification.message =
                    legalCompanyMissingCreditNumberError()
            }

            this.$store.dispatch('toastNotificationModule/add', toastNotification, {
                root: true
            })
        },
        isPurchaseAgreement(orderType) {
			return orderType?.toLowerCase() === 'purchaseagreement'
		}
    }
}

export default acceptRejectOrderMixin
