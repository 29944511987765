export function sortBy({sortOption, key}){                        
    // if sort key is the same change direction  
    if(sortOption.sortKey && sortOption.sortKey === key){
        // invert the direaction 
        sortOption.sortDirection = sortOption.sortDirection === 'asc' ? 'desc': 'asc' 
    } 
    // // if sort key is diferent change the key
    else {
        sortOption.sortKey = key
        sortOption.sortDirection = "asc" 
    } 
    
    return sortOption
}


export default { sortBy }