/*  this mixin contains logic to set filters based on selected data from pagination,   
    for account list, course list, provider list & assorted product event list
*/

const paginationMixin = {
    created() {
        const totalAmountOfPages = Math.ceil(Number(this.setPaginationOptions.totalCount) / Number(this.setPaginationOptions.itemsPerPageFromUrlQuery))

        // A Dynamic trigger of watch to avoid unnecessary calls
        this.$watch('setSelectedFilters', function() {

            this.updateList({ currentPage: 1, itemsPerPage: this.setPaginationOptions.itemsPerPageFromUrlQuery })
        }, {deep:true})

        if (this.$route.query.page > totalAmountOfPages && totalAmountOfPages !== 0) {

            this.updateList({ currentPage: 1, itemsPerPage: this.setPaginationOptions.itemsPerPageFromUrlQuery })
        }
    },
    watch: {
        'setPaginationOptions.totalCount': {
            handler() {      
                const totalAmountOfPages = Math.ceil(Number(this.setPaginationOptions.totalCount) / Number(this.setPaginationOptions.itemsPerPageFromUrlQuery))
                if(totalAmountOfPages === 0){
                    return
                }

                if(totalAmountOfPages < this.setPaginationOptions.currentPage) { 
                    this.updateList({ currentPage: 1, itemsPerPage: this.setPaginationOptions.itemsPerPageFromUrlQuery }) 
                }                
            },
            deep: true
        }
    },
    methods: {
        setQueryStringParam(selectedFilters, queryParam) { 
            if(selectedFilters) {
                // Set filter parameters list
                Object.keys(selectedFilters).map((key) => {
                    if(selectedFilters[key] || selectedFilters[key] === 0)
                    {
                        queryParam[key] = encodeURIComponent(selectedFilters[key])
                    }
                    return null
                })
            } 

            if(JSON.stringify(this.$route.query) !== JSON.stringify(queryParam)) {
                this.$router.replace({ query: queryParam }).catch(() => {
                    console.error('Page doesn\'t exist, redirecting to existing page.')
                })                 
            }
        },      
        setCurrentPageAsQueryParam() {
            let queryParam = {}
            // Remove if no value is defined on the query search parameter
            let refreshQueryParameters
            Object.keys(this.$route.query).forEach((key) => { 
                if (!this.$route.query[key]) {

                    refreshQueryParameters = true
                    this.setPaginationOptions.currentPage = 1
                }
                return null
            })  

            if (Number(this.$route.query.page) === this.setPaginationOptions.currentPage && Number(this.$route.query.page) > 1 &&
                (this.$route.query.itemsPerPage === undefined || (Number(this.$route.query.itemsPerPage) === this.setPaginationOptions.itemsPerPageFromUrlQuery) &&
                (this.setPaginationOptions.itemsPerPageFromUrlQuery !== this.setPaginationOptions.selectOptions[0].value)) && 
                !refreshQueryParameters) 
            {
                return
            }  

            queryParam = {
                page: `${this.setPaginationOptions.currentPage}`,
                itemsPerPage: `${this.setPaginationOptions.itemsPerPageFromUrlQuery}`
            }  

            this.setQueryStringParam(this.setSelectedFilters, queryParam)
        } 
    }
}

export default paginationMixin